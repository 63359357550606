<script>
import { storeForms } from '@app/mixins/forms';
import StoreCommentsSettings from '@app/components/StoreCommentsSettings.vue';
import StoreCustomerSessionSettings from '@app/components/StoreCustomerSessionSettings.vue';

export default {
  mixins: [storeForms],
  components: {
    StoreCommentsSettings,
    StoreCustomerSessionSettings,
  },
};
</script>

<template>
  <div>
    <StoreCommentsSettings />
    <StoreCustomerSessionSettings />
  </div>
</template>
