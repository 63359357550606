<script>
import { storeForms } from '@app/mixins/forms';

export default {
  mixins: [storeForms],
  data() {
    return {
      store: {
        feature_options: {
          comments: {
            disabled: false,
          },
        },
      },
    };
  },
};
</script>

<template>
  <AppFeatureWrapper>
    <template #title>
      Désactivez les commentaires
    </template>
    <template #content>
      <p>
        Désactivez les commentaires pour toutes vos formations.
      </p>
      <p>
        <strong>Attention</strong> : vous pouvez également gérer
        les commentaires formation par formation.
      </p>
    </template>
    <template #form>
      <div class="box is-relative">
        <b-switch
          v-model="store.feature_options.comments.disabled"
          @input="dataIsValid(isLoading) && handle()">
          Désactiver pour toutes les formations
        </b-switch>

        <b-loading v-model="isLoading" :is-full-page="false" />
      </div>
    </template>
  </AppFeatureWrapper>
</template>
