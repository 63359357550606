<script>
import { storeForms } from '@app/mixins/forms';

export default {
  mixins: [storeForms],
  data() {
    return {
      hasInactivityTimeoutEnabled: false,
      store: {
        feature_options: {
          customer_session: {
            inactivity_timeout: null,
            disabled_on_tab_quit: false,
          },
        },
      },
    };
  },
  created() {
    this.hasInactivityTimeoutEnabled = (
      this.store.feature_options.customer_session.inactivity_timeout > 0
    );
  },
  methods: {
    onInactivityTimeoutChange(value) {
      const customerSession = this.store.feature_options.customer_session;

      if (value) {
        this.$buefy.dialog.prompt({
          title: 'Rappel !',
          message: `
            <p class="mt-3">
              En activant cette option, le temps de connexion n'est pas comptabilisé
              quand votre apprenant fait un exercice ou une action en dehors de votre
              espace de formation une fois le délai d'inactivité atteint.
            </p>
            <p class="mt-3">
              Également, lorsque votre contenu sur une leçon ne demande pas d'interactivité
              (document à lire, vidéo à regarder), votre apprenant peut être amené
              à voir régulièrement le message d'alerte.
            </p>
            <p class="mt-3">
              <strong>
                Attention donc à ne pas définir un délai d'inactivité maximum trop court,
                qui risquerait de nuire à l'expérience de votre apprenant.
              </strong>
            </p>
            <p class="mt-3">
              Renseignez le temps d'inactivité maximum avant d'afficher l'alerte (en minutes)
            </p>
          `,
          inputAttrs: {
            placeholder: `
              temps d'inactivité (en minutes)
            `.trim(),
            type: 'number',
            min: 1,
          },
          focusOn: 'cancel',
          onConfirm: (value) => (customerSession.inactivity_timeout = Number(value)),
          onCancel: () => {
            this.hasInactivityTimeoutEnabled = false;
            customerSession.inactivity_timeout = null;
          },
        });

        return;
      }

      customerSession.inactivity_timeout = null;
    },
    onDisabledOnTabQuitChange(value) {
      const customerSession = this.store.feature_options.customer_session;

      if (value) {
        this.$buefy.dialog.confirm({
          title: 'Attention !',
          message: `
            <p>
              <strong>
                Quand votre apprenant fera un exercice ou une action en dehors de votre espace de formation,
                le temps passé ne sera pas comptabilisé.
              </strong>
            </p>
            <p class="mt-3">
              Information supplémentaire : détecter que l'apprenant a changé d'onglet
              fonctionne sur la majorité des navigateurs, mais
              <strong>pas sur les plus anciens</strong>.
            </p>
          `,
          focusOn: 'cancel',
          onConfirm: () => (customerSession.disabled_on_tab_quit = value),
          onCancel: () => (customerSession.disabled_on_tab_quit = !value),
        });

        return;
      }

      customerSession.disabled_on_tab_quit = value;
    },
  },
};
</script>

<template>
  <AppFeatureWrapper>
    <template #title>
      Logs et temps de connexion <AppFeatureBadge class="ml-3" position="is-right" />
    </template>
    <template #content>
      <p>
        Configurez la façon dont les logs de connexion
        (aussi appelés temps de connexion) doivent être gérés.
      </p>
      <p>
        Teachizy enregistre <strong>le temps passé sur chaque leçon</strong>
        et <strong>non le temps passé sur la plateforme</strong> en général.
      </p>
      <p>
        Par exemple, le temps passé par un apprenant sur son tableau de bord
        n'est pas pris en compte dans ses logs de connexion.
      </p>
    </template>
    <template #form>
      <GoProWrapper :hasSep="false">
        <form class="box" @submit.prevent="dataIsValid(isLoading) && handle()">
          <b-field>
            <b-switch v-model="hasInactivityTimeoutEnabled" @input="onInactivityTimeoutChange">
              Activer le vérificateur d'activité "<strong>Êtes-vous toujours là ?</strong>"
            </b-switch>
          </b-field>
          <b-field
            v-if="hasInactivityTimeoutEnabled"
            label="Temps d'inactivité avant d'afficher l'alerte (en minutes)">
            <b-input
              type="number"
              min="1"
              v-model.number="store.feature_options.customer_session.inactivity_timeout"
            />
          </b-field>
          <div class="notification content">
            <p>
              Après avoir commencé une leçon, vos apprenants peuvent vaquer à leurs occupations
              et tant que l'onglet de la leçon est ouvert, nous continuons de comptabiliser
              leur temps de connexion dans les logs.
            </p>
            <p>
              Vous pouvez activer si vous le souhaitez <strong>un message d'alerte qui demandera
              à l'apprenant si il est toujours là</strong>. Ce message apparaît après une période
              d'inactivité dont vous pouvez définir la durée.
            </p>
            <p>
              Dans ce cas là, tant que l'apprenant ne confirme pas sa présence par <strong>un clic
              sur le bouton "oui"</strong>, le temps de connexion cesse d'être comptabilisé.
            </p>
          </div>
          <b-field>
            <b-switch
              v-model="store.feature_options.customer_session.disabled_on_tab_quit"
              @input="onDisabledOnTabQuitChange">
              Arrêter de comptabiliser le temps quand l'apprenant change d'onglet
            </b-switch>
          </b-field>
          <div class="notification content">
            <p>
              Quand l'apprenant change l'onglet ou la fenêtre de la leçon
              <strong>nous arrêterons d'enregistrer les logs de connexion</strong>.
            </p>
            <p>
              À partir du moment où l'apprenant revient sur la leçon,
              <strong>nous recommençons à enregistrer les logs</strong>.
            </p>
          </div>
          <b-field class="has-text-right">
            <b-button type="is-primary" native-type="submit" :loading="isLoading">
              Mettre à jour
            </b-button>
          </b-field>
        </form>
      </GoProWrapper>
    </template>
  </AppFeatureWrapper>
</template>
